import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import CountUp from 'react-countup';
import Slider from "react-slick";
import { PortfolioList } from "../HomePageScript";
import "react-slick/dist/slick.min.css";
import VisibilitySensor from 'react-visibility-sensor';
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ProjectList = [
    {
        ImageName: 'portfolio-1',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    },
    {
        ImageName: 'portfolio-2',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    },
    {
        ImageName: 'portfolio-3',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    },
    {
        ImageName: 'portfolio-4',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    }
]

const CounterData = [
    {
        countNum: 199,
        countTitle: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those.',
    },
    {
        countNum: 575,
        countTitle: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those.',
    },
    {
        countNum: 69,
        countTitle: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those.',
    },
];


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Static React Website'
    },
    {
        icon: <FiLayers />,
        title: 'Wordpress CMS Website',
    },
    {
        icon: <FiUsers />,
        title: 'Ecommerce Website',
    },
    { 
        icon: <FiMonitor />,
        title: 'Blogs',
    }
];

const ServiceList2 = [
    {
        icon: <FiCast />,
        title: 'Health Care'
    },
    {
        icon: <FiLayers />,
        title: 'Education',
    },
    {
        icon: <FiUsers />,
        title: 'Manufacturing',
    },
    { 
        icon: <FiMonitor />,
        title: 'IT Technology',
    },
    { 
        icon: <FiMonitor />,
        title: 'Transport and Logistics',
    },
    { 
        icon: <FiMonitor />,
        title: 'Consulting Service',
    },
    { 
        icon: <FiMonitor />,
        title: 'Online Shopping',
    },
    { 
        icon: <FiMonitor />,
        title: 'Entertainment',
    },
    { 
        icon: <FiMonitor />,
        title: 'Charity & much',
    }
];

export default class About extends Component {

    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }

    render() {
        return (

            <div class="AboutScreen">
                <Helmet>
                    <title>About SaiCreatives</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div class="breadcrumb-area rn-bg-color bg_image bg_image--1 text-center" data-black-overlay="6"><div class="container"><div class="row"><div class="col-lg-12"><div class="breadcrumb-inner pt--100"><h2 class="title">About</h2><ul class="page-list"><li class="breadcrumb-item"><a href="/">Home</a></li><li class="breadcrumb-item active">About</li></ul></div></div></div></div></div>

               <section className="home_row_4 pt_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">

                                <img className="w-100 b-radius-5" src="assets/images/about-3.jpg" alt="About Images" />

                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title pb-4">
                                        <h2 className="head_2">About</h2>
                                        <p className="description">The Colors Web is a best web design company located in Chennai, india. Our parent company is Webszilla Technologies. Webszilla Technologies is the independent, end-to-end IT services company. Our web design experts have created creative websites – designed with search engines in mind. We are a gathering of exceptionally gifted people who share a typical love for the work we convey. We continually endeavour to keep up and update our insight, mastery and administration contributions so as to best serve our customers' needs.</p>
                                    </div>
                                    <div className="row mt--30">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="head_5">Value</h3>
                                                <p>We are focused on offering the absolute best worth. Customers will just compensation for the services required and we think about all parts of each task - innovation, improvement, preparing and continuous help to guarantee that we bolster our clients in settling on the best and most efficient choices.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="head_5">Transparency</h3>
                                                <p>It is significant that our customer's know precisely what they are paying for. We give definite data about the work we attempt for their sake.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="AbtRow_2 pt_100">
                    <div class="container">
                        <div className="section-title pb-4 text-center">
                            <h2 className="head_2">Our Fun Facts</h2>
                            <p className="description">There are many variations of passages of Lorem Ipsum available.</p>
                        </div>
                        <div className="row">
                            {CounterData.map((value, index) => (
                                <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 text-center col-12" key={index}>
                                    <h5 className="counter">
                                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                            <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                        </VisibilitySensor>
                                    </h5>
                                    <p className="description">{value.countTitle}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
				
				<section class="ss_1 pt_100">
                    <div className="container">
                        
                        <div className="section-title pb-4 text-center">
                            <h2 className="head_2">Our Service</h2>
                           
                        </div>
                        <div className="row">
                        {ServiceList.map( (val , i) => (
                        <div className="col-lg-3 col-sm-6 col-12" key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                        </div>
                    </div>
                </section>
				
				
				<section class="ss_1 pt_100">
                    <div className="container">
                        
                        <div className="section-title pb-4 text-center">
                            <h2 className="head_2">Industries</h2>
                           
                        </div>
                        <div className="row">
                        {ServiceList2.map( (val , i) => (
                        <div className="col-lg-3 col-sm-6 col-12" key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                        </div>
                    </div>
                </section>
				
                <section className="home_row_5 pt_100">
                    <div className="container">

                        <div className="section-title pb-4">
                            <h2 className="head_2">Portfolio</h2>
                            <p className="description">There are many variations of passages of Lorem Ipsum available.</p>
                        </div>

                        <div className="port_main_row">
                            <Slider {...PortfolioList}>
                                {ProjectList.map((val, i) => (
                                    <div key={i}>
                                        <div className="portfolio">    <img src={`assets/images/${val.ImageName}.jpg`} alt={val.alt} title={val.title} />
                                            <div className="content">
                                                <h3>{val.ImageText1}</h3>
                                                <h2>{val.ImageText2}</h2>
                                                <a href="#" className="btn_link_2 mt-2">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>

                    </div>

                </section>
            </div>
        )
    }
}