import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Static React Website'
    },
    {
        icon: <FiLayers />,
        title: 'Wordpress CMS Website',
    },
    {
        icon: <FiUsers />,
        title: 'Ecommerce Website',
    },
    { 
        icon: <FiMonitor />,
        title: 'Blogs',
    }
];

const ServiceList2 = [
    {
        icon: <FiCast />,
        title: 'Health Care'
    },
    {
        icon: <FiLayers />,
        title: 'Education',
    },
    {
        icon: <FiUsers />,
        title: 'Manufacturing',
    },
    { 
        icon: <FiMonitor />,
        title: 'IT Technology',
    },
    { 
        icon: <FiMonitor />,
        title: 'Transport and Logistics',
    },
    { 
        icon: <FiMonitor />,
        title: 'Consulting Service',
    },
    { 
        icon: <FiMonitor />,
        title: 'Online Shopping',
    },
    { 
        icon: <FiMonitor />,
        title: 'Entertainment',
    },
    { 
        icon: <FiMonitor />,
        title: 'Charity & much',
    }
];


export default class Services extends Component {
    render() {
        return (
            <div class="ServiceScreen">
                <Helmet>
                    <title>Services SaiCreatives</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div class="breadcrumb-area rn-bg-color bg_image bg_image--1 text-center" data-black-overlay="6"><div class="container"><div class="row"><div class="col-lg-12"><div class="breadcrumb-inner pt--100"><h2 class="title">Services</h2><ul class="page-list"><li class="breadcrumb-item"><a href="/">Home</a></li><li class="breadcrumb-item active">Services</li></ul></div></div></div></div></div>

                <section class="ss_1 pt_100">
                    <div className="container">
                        
                        <div className="section-title pb-4 text-center">
                            <h2 className="head_2">Our Service </h2>
                        </div>
                        <div className="row">
                        {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-sm-6 col-12" key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                        </div>
                    </div>
                </section>
				
				
				<section class="ss_1 pt_100">
                    <div className="container">
                        
                        <div className="section-title pb-4 text-center">
                            <h2 className="head_2">Industries</h2>
                           
                        </div>
                        <div className="row">
                        {ServiceList2.map( (val , i) => (
                        <div className="col-lg-3 col-sm-6 col-12" key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}