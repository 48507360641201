import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Header extends Component {
  render() {
    return (
      <header>

        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-lg w-100">
              <a className="navbar-brand" href="#">
              <img src="assets/images/the-colors-web-logo.png"/>
      </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#TopNavMenu" aria-controls="TopNavMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse justify-content-md-end" id="TopNavMenu">
                <ul className="navbar-nav custom_nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" exact activeClassName="active" to="/">Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" exact activeClassName="active" to="/About">About us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" exact activeClassName="active" to="/Services">Services</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" exact activeClassName="active" to="/Contact">Contact</NavLink>
                  </li>

                </ul>

              </div>
            </nav>
          </div>
        </div>

      </header>
    )
  }
}