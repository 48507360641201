import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { slideSlick, PortfolioList } from "../HomePageScript";
import "react-slick/dist/slick.min.css";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";

const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--32',
        category: '',
        title: 'Business.',
        description: 'There are many variations of passages but the majority have suffered alteration.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Agency.',
        description: 'There are many variations of passages but the majority have suffered alteration.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    
]


const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'Ultra Fast Static Website',
        description: 'We create react website from starch. High loading speed with rich website layout designs starts @ $250.  (Example: Check it out our react website speed)'
    },
    {
        icon: <FiLayers />,
        title: 'Dynamic Website',
        description: 'We are doing the wordpress websites with unique theme. Website creates from starch. Divi premium builder will help to manage the pages easily from client side. Dynamic Website Create from Starch Starts @ $950.'
    },
    {
        icon: <FiUsers />,
        title: 'Ecommerce Website',
        description: 'We provide cost effective ecommerce web solution. Website Creating methods: Pre build readymade website, Premium theme based website creation and own starch theme websites. Ecommerce Pre Made Website Start @ $550 (Payment Gateway integration charges extra*)'
    },
]

const ProjectList = [
    {
        ImageName: 'portfolio-1',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    },
    {
        ImageName: 'portfolio-2',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    },
    {
        ImageName: 'portfolio-3',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    },
    {
        ImageName: 'portfolio-4',
        alt: 'Image alt name',
        title: 'Image title name',
        ImageText1: 'Development',
        ImageText2: 'Getting tickets to the big show',
    }
]



export default class Home extends Component {
    render() {
        return (
            <div className="HomeScreen">
                <Helmet>
                    <title>Welcome SaiCreatives</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <section className="slider_row">
                   {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                </section>
                <section className="home_row_2 pt_100">
                    <div className="container">
                        <div className="row">
                            {ServiceListOne.map((val, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <a className="text-center d-block" href="#">
                                        <div className="icon_1">
                                            {val.icon}
                                        </div>
                                        <article className="content">
                                            <h2 className="head_1">{val.title}</h2>
                                            <p>{val.description}</p>
                                        </article>
                                    </a>
                                </div>
                            ))}
                        </div>

                    </div>
                </section>

                <section className="home_row_4 pt_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">

                                <img className="w-100 b-radius-5" src="assets/images/about-3.jpg" alt="About Images" />

                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title pb-4">
                                        <h2 className="head_2">About</h2>
                                        <p className="description">The Colors Web is a best web design company located in Chennai, india. Our parent company is Webszilla Technologies. Webszilla Technologies is the independent, end-to-end IT services company. Our web design experts have created creative websites – designed with search engines in mind. We are a gathering of exceptionally gifted people who share a typical love for the work we convey. We continually endeavour to keep up and update our insight, mastery and administration contributions so as to best serve our customers' needs.</p>
                                    </div>
                                    <div className="row mt--30">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="head_5">Value</h3>
                                                <p>We are focused on offering the absolute best worth. Customers will just compensation for the services required and we think about all parts of each task - innovation, improvement, preparing and continuous help to guarantee that we bolster our clients in settling on the best and most efficient choices.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="head_5">Transparency</h3>
                                                <p>It is significant that our customer's know precisely what they are paying for. We give definite data about the work we attempt for their sake.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="home_row_5 pt_100">
                    <div className="container">

                        <div className="section-title pb-4">
                            <h2 className="head_2">Portfolio</h2>
                            <p className="description">There are many variations of passages of Lorem Ipsum available.</p>
                        </div>

                        <div className="port_main_row">
                            <Slider {...PortfolioList}>
                                {ProjectList.map((val, i) => (
                                    <div key={i}>
                                        <div className="portfolio">    <img src={`assets/images/${val.ImageName}.jpg`} alt={val.alt} title={val.title} />
                                            <div className="content">
                                                <h3>{val.ImageText1}</h3>
                                                <h2>{val.ImageText2}</h2>
                                                <a href="#" className="btn_link_2 mt-2">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>

                    </div>

                </section>

                <section className="home_row_6 pt_100 rn-pricing-table-area">
                    <div className="container">
                        <div className="section-title pb-4 text-center">
                            <h2 className="head_2">Pricing Plan</h2>
                            <p className="description">There are many variations of passages of Lorem Ipsum available.</p>
                        </div>

                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Free</h4>
                                            <div className="pricing">
                                                <span className="price">29</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Business</h4>
                                            <div className="pricing">
                                                <span className="price">29</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                            <div className="pricing">
                                                <span className="price">29</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>


                    </div>
                </section>


                <section class="home_row_7 pt_100">
                    <div className="contact-form--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-6 order-2 order-lg-1">

                                    <div className="section-title pb-4">
                                        <h2 className="head_2">Contact Us</h2>
                                        <p className="description">There are many variations of passages of Lorem Ipsum available.</p>
                                    </div>

                                    <div className="form-wrapper">
                                        <form>
                                            <label htmlFor="item01">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Your Name *"
                                                />
                                            </label>

                                            <label htmlFor="item02">
                                                <input
                                                    type="text"
                                                    name="email"


                                                    placeholder="Your email *"
                                                />
                                            </label>

                                            <label htmlFor="item03">
                                                <input
                                                    type="text"
                                                    name="subject"

                                                    placeholder="Write a Subject"
                                                />
                                            </label>
                                            <label htmlFor="item04">
                                                <textarea
                                                    type="text"
                                                    name="message"
                                                    placeholder="Your Message"
                                                />
                                            </label>
                                            <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2">
                                    <img class="w-100" src="assets/images/about-6.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        )
    }
}