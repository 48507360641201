import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import Contact from './Components/Contact';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename=''>
       <Header/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/About"  component={About}/>
        <Route path="/Services" component={Services}/>
        <Route path="/Contact" component={Contact}/>
      </Switch>
      <Footer/>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
