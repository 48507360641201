import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import contactform from '../contact_form.php';
import $ from 'jquery';


export default class Contact extends Component {
    componentDidMount(){
        $(document).ready(function() {
            $('form#contact_form_1').submit(function() {		
            var hasError = false;
            if(!hasError) {
                var formInput = $(this).serialize();
                $('.form-process').show();
                $.post($(this).attr('action'),formInput, function(data){
                    $('.form-process').hide();
                        $('#succes_message').show();
                });
            }
            return false;	
        });

        $("#mobile").keydown(function(event) {
            if ( event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 || 
                (event.keyCode == 65 && event.ctrlKey === true) || 
                (event.keyCode >= 35 && event.keyCode <= 39)) {
                     return;
            }
            else {
                if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
                    event.preventDefault(); 
                }   
            }
        });
     });
    }
    render() {
        return (
            <div className="ContactScreen">
                <Helmet>
                    <title>Contact SaiCreatives</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div className="breadcrumb-area rn-bg-color bg_image bg_image--1 text-center" data-black-overlay="6"><div className="container"><div className="row"><div className="col-lg-12"><div className="breadcrumb-inner pt--100"><h2 className="title">Contact</h2><ul className="page-list"><li className="breadcrumb-item"><a href="/">Home</a></li><li className="breadcrumb-item active">Contact</li></ul></div></div></div></div></div>

                <section className="cs_row_1 pt_100">
                    <div className="rn-contact-top-area bg_color--5">
                        <div className="container">

                            <div className="row">
                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12  d-flex">
                                    <div className="rn-address d-flex">
                                        <div className="icon">
                                            <FiHeadphones />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Contact With Whatsapp  Number</h4>
                                            <p><a href="tel:+91 87785 34456">India: +91 87785 34456</a></p>
                                            <p><a href="tel:1 587 990 4836">Canada: +1 587 990 4836</a></p>
                                            <p><a href="skype:thecolorsweb?chat">Skype: thecolorsweb</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50 d-flex">
                                    <div className="rn-address d-flex">
                                        <div className="icon">
                                            <FiMail />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Email Address</h4>
                                            <p><a href="mailto:info@thecolorsweb.com">info@thecolorsweb.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50  d-flex">
                                    <div className="rn-address d-flex">
                                        <div className="icon">
                                            <FiMapPin />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Location</h4>
                                            <p>India, <br /> Canada</p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                            </div>
                        </div>
                    </div>
                </section>

                <section className="home_row_7 pt_100">
                    <div className="contact-form--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-6 order-2 order-lg-1">

                                    <div className="section-title pb-4">
                                        <h2 className="head_2">Contact Us</h2>
                                        <p className="description">There are many variations of passages of Lorem Ipsum available.</p>
                                    </div>

                                    <div className="form-wrapper">
                                        <form id="contact_form_1" action={contactform} method="post">
                                            <h1>Quick Enquiry</h1>
                                            <input type="text" name="contactName" id="contactName" placeholder="sdaf" required />
                                            <input type="tel" name="mobile" id="mobile" placeholder="Contact Number"  required />
                                            <input type="email" name="email" id="email" placeholder="Email"  className="email form_input" required />
                                            <textarea name="address" rows="3" placeholder="your Requirment" id="address" required></textarea>
                                            <button className="btn_green" type="submit" id="sendMessage" name="sendMessage" value="submit">Send Message
                      </button>
                                            <input type="hidden" name="submitted" id="submitted" value="true" />
                                            <p className="text-success pt-4" id="succes_message"> Received your message, We will communicate with you shortly.
                      </p>
                                        </form>

                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2">
                                    <img className="w-100" src="assets/images/about-6.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}